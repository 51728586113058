@use "sass:color";
@import "styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.iconSearch {
  border-left: none !important;
  border-right: none !important;
}

.link {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  font-size: 0.9rem;
  height: 50px;
  transition: background-color 0.3s;
  font-family: $font-family-avnir;
  font-weight: 500;
  cursor: pointer;
  margin: 0 7px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: color.scale($header-bar-bg, $lightness: 4%);
    color: #000;
  }
}

.iconLink {
  font-size: 1rem;
  width: 50px;
  transition: all 0.3s ease-in-out;
}
