@import "../../../../styles/variables";
@import "../../../../styles/mixins/button";

.root {
  @include btn-base;
  @include btn-poppins;
  @include btn-gradient-red-black;
  position: relative default;
  > a {
    &:hover {
      text-decoration: none;
      color: unset;
    }
  }
}
