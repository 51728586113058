@use "sass:color";
@import "styles/variables";

.link {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0.9rem;
  height: 50px;
  transition: background-color 0.3s;
  font-family: $font-family-avnir;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: color.scale($header-bar-bg, $lightness: 4%);
  }

  & > a > span,
  & > span {
    padding-right: 0.2rem;
  }

  & > a > * {
    color: #fff;
  }

  &[class*="active"] {
    background-color: $redLight;
  }
}

.iconLink {
  width: 50px;
  transition: border-left-color 0.3s;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1200px) {
    border-left: 1px solid #fff !important;

    &:last-of-type {
      border-right: 1px solid #fff !important;
    }

    &:hover {
      border-left-color: rgba(255, 255, 255, 0.3) !important;
    }
  }
}

.iconSearch {
  border-left: none !important;
  border-right: none !important;
}

.navLinks {
  margin-right: auto;
  .link {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}
