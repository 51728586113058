@use "sass:color";
@import "styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.root,
.navBar {
  background-color: $header-bar-bg;
  padding: 0 0.75rem;
  width: 100%;
  z-index: 999;
  min-height: 50px;
  max-height: 50px;
}

.root {
  position: fixed;
}

.navBar {
  padding: 0 0.75rem 0 0;
  position: relative;

  @include media-breakpoint-up(lg) {
    padding: 0;
  }
}

.withShadow {
  box-shadow: 0 0 25px rgb(0 0 0);
}
