.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;

  .main {
    flex-grow: 1;
    // overflow-y: auto;
    // overflow-x: hidden;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
