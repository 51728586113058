@use "sass:color";
@import "styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.root {
  width: 360px;
  background-color: $softGrey5;
  position: absolute;
  z-index: 999;
  color: inherit;
  cursor: auto;

  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);

  @media screen and (max-width: 380px) {
    width: 100vw;
  }
}

.mainText {
  color: $softDark3;
  text-transform: uppercase;
  word-break: break-all;
}

.bottom {
  top: 100%;
  right: 0;
}

.right {
  left: 100%;
  bottom: 0;
}

.content {
  color: white;
  text-align: center;
}

.show {
  visibility: visible;
  opacity: 1;
  transition: all 300ms ease-in-out;
}

.hide {
  visibility: hidden;
  opacity: 0;
  transition: all 300ms ease-in-out;
}

.proMovieIcon {
  margin-bottom: 1rem;
  fill: #000;
}

.links {
  font-size: 1rem;
  font-weight: 400;
  text-transform: none;
  font-family: $font-family-base;
  color: $softDark3;

  a {
    &:hover {
      text-decoration: none;
      color: $red;
    }
  }
}
