@mixin new-badge($bg-color: red, $text-color: white) {
	position: absolute;
	display: flex;
	content: 'new!';
	font-size: 8px;
	align-items: center;
	justify-content: center;
	bottom: 0;
	right: 0;
	transform: translate(0%, -50%) rotate(-45deg);
	padding: 2px 7px;
	border-radius: 10px;
	color: $text-color;
	background-color: $bg-color;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}
