@mixin fade-in(
  $transition: "opacity 0.3s ease-in-out, visibility 0.3s ease-in-out"
) {
  opacity: 1;
  visibility: visible;
  transition: $transition;
  pointer-events: all;
}

@mixin fade-out(
  $transition: "opacity 0.3s ease-in-out, visibility 0.3s ease-in-out"
) {
  opacity: 0;
  visibility: hidden;
  transition: $transition;
  pointer-events: none;
}
