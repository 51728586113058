@import "styles/variables";
@import "styles/mixins/animation";

.submenuLink {
  font-family: $font-family-avnir;
}

.linksWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: $softGrey5;

  &.default {
    .submenuLink {
      display: block;
      font-weight: 500;
      padding: 1rem;
    }
  }
  &.full-width {
    padding: 0.5rem 0;
    align-items: flex-end;

    .submenuLink {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      padding: 0.75rem 1rem;
      min-width: 250px;
    }
  }
  .submenuLink {
    color: inherit;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.35;
    width: 100%;
    transition: all 250ms ease-in-out;
  }
}

.horizontal .linksWrapper {
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem 0 0;

  & .submenuLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 0.375rem 0.75rem;
    min-width: 150px;
    width: fit-content;
    margin: 0 1rem 1rem 0;

    border-radius: 4px;
  }
}

.itemsList {
  display: flex;
  width: fit-content;
  justify-content: flex-start;
  overflow: auto;

  cursor: grab;

  padding: 0.5rem;
  margin-bottom: 0;
  list-style: none;

  scrollbar-width: auto;

  &::-webkit-scrollbar {
    height: 6px !important;
    background: $red;
    cursor: default !important;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $red;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $redDark;
  }

  & li {
    // min-width: 250px;
    display: block;
    align-items: flex-start;
    // scroll-snap-align: start;
  }

  & a {
    pointer-events: unset;
  }

  .horizontal & {
    justify-content: stretch;
  }
}

.isOver {
  & a {
    pointer-events: all;
  }
}

.active {
  border-color: #f00 !important;
  background-color: #f00 !important;
  color: white;
}

.cardItem {
  align-items: flex-start;
  width: calc(20% - 0.5rem);
  min-width: 320px;

  &.compact {
    min-width: 250px;
  }

  & p {
    font-size: 0.75rem;
  }
}

.listWrapper {
  width: 100%;
  padding: 0.5rem;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  position: relative;

  &.withPadding {
    padding: 1.75rem 0.5rem 0.5rem;
  }

  .vertical & {
    justify-content: flex-start;
  }
  .horizontal & {
    justify-content: center;
  }
}

.noSnap {
  scroll-snap-type: none;

  & a,
  li,
  img,
  p {
    // cursor: grabbing !important;
    pointer-events: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}

.horizontal {
  flex-direction: column;
}

.vertical {
  flex-direction: row;
}

.compact div {
  padding-top: 0.75rem;
  padding-bottom: 1rem;
  overflow: hidden;

  & a:first-child {
    font-size: 0.75rem;
    margin-bottom: 0.75rem !important;
    padding: 4px 8px;
  }

  & a:last-child {
    display: block;
    width: 100%;
    // width: fit-content;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 1rem;
  }
}

.title {
  position: absolute;
  top: 0.75rem;
  left: 1.5rem;
  font-size: 1.25rem;
  // font-family: $font-family-avnir;
}
