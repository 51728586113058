@use "sass:color";
@import "styles/variables";
@import "styles/mixins/button";

.root {
  @include btn-base;
  @include btn-transparent(transparentize(#fff, 0.9), $red);
  text-decoration: none;
  &:hover {
    color: white;
    text-decoration: none;
  }
}
