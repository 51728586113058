@import "./hover";
@import "../variables";

@mixin btn-red-hover {
  @include hover($red, #fff, $darkCyan, #fff);
}

@mixin btn-base {
  border: 1px solid transparent;
  border-radius: 0;
  padding: 0.357em 1.07em;
  font-family: $font-family-avnir;
  font-size: 0.75rem;
  transition: background-color 0.3s, border 0.3s;
  text-transform: uppercase;

  &:focus,
  &:active {
    box-shadow: none !important;
  }
}

@mixin btn-poppins {
  font-family: $font-family-poppins-bold;
  font-weight: 500;
}

@mixin btn-transparent($border-color: #fff, $bg-color: #f00) {
  border-color: $border-color !important;
  background-color: transparent !important;

  &:hover,
  &:active,
  &[class*="active"] {
    // border-color: $bg-color !important;
    // background-color: $bg-color !important;

    border-color: #fafafa !important;
    // border-color: $bg-color !important;
    color: $bg-color !important;
    background-color: #fafafa !important;
  }
}

// @mixin btn-gradient-red {
//   border: 0;
//   color: $btn-red-gradient-text;
//   background-color: $btn-red-bg;
//   background: $btn-red-gradient-bg;
//   transition: color 0.3s, background-color 0.3s, border-color 0.3s, opacity 0.3s,
//     visibility 0.3s;
//   padding: 1em 1.8em;
//   font-weight: 700;

//   &:hover,
//   &:active,
//   &[class*="active"] {
//     border: 0;
//     color: $btn-grey-gradient-text;
//     background-color: $btn-grey-bg;
//     background: $btn-grey-gradient-bg;
//   }
// }

// @mixin btn-gradient-black {
//   border: 0;
//   color: white;
//   background-color: $btn-red-bg;
//   background: $btn-red-gradient-bg;
//   transition: all 0.3s ease-in-out;
//   padding: 1em 1.8em;
//   font-weight: 700;
//   &:hover,
//   &:active,
//   &[class*="active"] {
//     border: 0;
//     color: white;
//     background-color: $softDark;
//     background: $btn-black-gradient-bg;
//     transition: all 0.3s ease-in-out;
//   }
// }

@property --a {
  syntax: "<color>";
  inherits: false;
  initial-value: #cc0000;
}

@property --b {
  syntax: "<color>";
  inherits: false;
  initial-value: #b80000;
}

@mixin btn-gradient-red-white {
  --a: #cc0000;
  --b: #b80000;
  &:before {
    content: none;
  }
  color: #ffffff;

  background-color: #cc0000;
  background: -webkit-linear-gradient(
    to left bottom,
    var(--a) 50%,
    var(--b) 50%
  );
  background: -moz-linear-gradient(to left bottom, var(--a) 50%, var(--b) 50%);
  background: linear-gradient(to left bottom, var(--a) 50%, var(--b) 50%);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  margin: 0;
  cursor: pointer;
  text-align: center;
  width: fit-content;
  min-width: 130px;
  border-radius: 3px;
  box-shadow: none;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s, opacity 0.3s,
    visibility 0.3s, --a 0.3s, --b 0.3s;
  -webkit-transition: color 0.3s, background-color 0.3s, border-color 0.3s,
    opacity 0.3s, visibility 0.3s, --a 0.3s, --b 0.3s;
  -webkit-box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  font-family: $font-family-poppins-bold;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.35;
  letter-spacing: 0;
  font-style: normal;
  text-transform: uppercase;
  padding: 1em 1.8em;

  &:hover {
    color: #000000;

    --a: #ffffff;
    --b: #e6e6e6;
  }
}

@mixin btn-gradient-red-black {
  @include btn-gradient-red-white;

  &:hover {
    color: #fff;

    --a: #333;
    --b: #000000;
  }
}
