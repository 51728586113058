@import "styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.text {
  margin-top: 25px;
  margin-bottom: 15px;
  color: #eaeaea;
  font-weight: bold;
  font-size: 100px;
  text-align: center;
  @include media-breakpoint-down(lg) {
    font-size: 72px;
  }
  @include media-breakpoint-down(md) {
    font-size: 48px;
  }
  @include media-breakpoint-down(xs) {
    font-size: 32px;
  }
}
