.root {
  border: 1px solid rgba(255, 255, 255, 255);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  border-radius: 4px;
  &:hover .rootClose {
    transform: rotate(225deg);
  }
}
.rootClose {
  transform: rotate(45deg);
  transform-origin: center;
  transition: 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rootOpen {
  display: block;
  float: none;
  width: 29px;
  height: 29px;
  overflow: hidden;
  position: relative;
  user-select: none;
  transition: transform 0.3s ease-in-out, left 0.3s ease-in-out,
    right 0.3s ease-in-out, opacity 0.3s ease-in-out;

  & > * {
    display: block;
    position: absolute;
    width: 16px;
    height: 1px;
    border: none;
    background: none;
    left: 6px;
    top: 50%;
    transition: transform 0.3s ease-in-out, left 0.3s ease-in-out,
      opacity 0.3s ease-in-out;

    &:nth-child(1) {
      margin-top: -5px;
    }

    &:nth-child(3) {
      margin-top: 5px;
    }

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: white;
      transition: transform 0.3s ease-in-out, left 0.3s ease-in-out,
        opacity 0.3s ease-in-out, background-color 0.3s ease;
    }

    &:nth-child(4),
    &:nth-child(5) {
      opacity: 0;
      transition: none;
      -webkit-transition: none;
    }

    &:nth-child(1):after,
    &:nth-child(2):after,
    &:nth-child(3):after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: -200px;
      background-color: white;
      transition: transform 0.3s ease-in-out, left 0.3s ease-in-out,
        opacity 0.3s ease-in-out, background-color 0.3s ease;
      -webkit-transition: transform 0.3s ease-in-out, left 0.3s ease-in-out,
        opacity 0.3s ease-in-out, background-color 0.3s ease;
    }

    &:nth-child(2):before,
    &:nth-child(2):after {
      transition-delay: 0.2s;
      -webkit-transition-delay: 0.2s;
    }

    &:nth-child(3):before,
    &:nth-child(3):after {
      transition-delay: 0.4s;
      -webkit-transition-delay: 0.4s;
    }
  }

  &:hover {
    & > * {
      &:nth-child(1):before,
      &:nth-child(2):before,
      &:nth-child(3):before {
        left: 200%;
      }

      &:nth-child(1):after,
      &:nth-child(2):after,
      &:nth-child(3):after {
        left: 0;
      }
    }
  }
}
