@use "sass:color";
@import "styles/variables";
@import "styles/mixins/animation";
@import "styles/mixins/new-badge";

.link {
  display: block;
  color: #fff !important;
  text-transform: uppercase;
  font-family: $font-family-avnir;
  font-size: 0.9rem;
  font-weight: 500;
  min-height: 50px;
  &[class*="active"] {
    background-color: $redLight;
  }
  &[data-new="true"] {
    position: relative;
    overflow: hidden;
    padding-right: 30px !important;
    &::after {
      @include new-badge($lightBlue);
    }
  }
}

.icon {
  margin-left: 0.5rem;
}

.navItem {
  transition: background-color 0.3s;
  &.default {
    position: relative;
    .subMenu {
      width: max-content;
      max-width: 360px;
      min-width: 200px;
    }
  }
  &.full-width {
    .subMenu {
      // width: 100%;
      text-align: center;
      display: flex;
    }
    &.horizontal {
      display: flex;
      flex-direction: column;
    }
  }

  .subMenu {
    width: calc(100% - 0.75rem);
    position: absolute;
    left: 0;
    top: 100%;
    // background-color: $menu-header-item-bg;
    background-color: #fafafa;
    // backdrop-filter: blur(8px);
    z-index: 10;

    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);

    @include fade-out($menu-header-item-transition);
  }
  .subMenu svg {
    pointer-events: none;
  }
  &:hover {
    background-color: color.scale($header-bar-bg, $lightness: 4%);
  }
  &:hover {
    .subMenu {
      @include fade-in($menu-header-item-transition);
    }
    .subMenu svg {
      pointer-events: all;
    }
  }
}
